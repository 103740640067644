import React, { useEffect, useContext } from "react"
import { navigate } from "gatsby"
import { BranchContext } from "@providers"

import { Layout2022Q3 } from "@layout"
import { HeroGetAtmApp2022Q3 } from "@components/hero"
import SEO from "@components/seo"

const GetAtmApp = () => {
  const { downloadURL } = useContext(BranchContext)

  useEffect(() => {
    const userAgent =
      typeof navigator !== "undefined" ? navigator.userAgent : null

    if (userAgent.match(/Android/i) || userAgent.match(/iPhone/i)) {
      navigate(downloadURL)
    }
  }, [])

  return (
    <Layout2022Q3 backgroundImage="animated" hero={HeroGetAtmApp2022Q3}>
      <SEO
        title="Download ATM.com App"
        description="Discover ATM.com, the micro-income app designed to help you earn more money. By making saving and investing a rewarding journey, we're enabling users to enhance their financial potential. Download our app today and turn your time into money!"
        image="/img/og/download-the-app.png"
      />
    </Layout2022Q3>
  )
}

export default GetAtmApp
